.tile {
    @apply flex-none rounded-md bg-slate-950 p-2 transition duration-200 sm:p-4;

    .__image-container {
        @apply relative h-32 shadow-xl sm:h-48 2xl:h-56 flex-none;

        .__image-elem {
            @apply h-full w-full rounded object-cover shadow-md;
        }

        .__image-placeholder {
            @apply flex h-full w-full items-center justify-center rounded bg-slate-900 shadow-md;

            .__icon-container {
                @apply text-slate-800;
            }
        }
    }

    .__content {
        @apply flex flex-1;

        .__left {
            @apply flex flex-1 flex-col space-y-2 w-full;

            .__text-container {
                @apply w-full flex-1;

                .__text-title {
                    @apply font-roboto text-sm font-black tracking-tight text-white sm:text-base;
                }

                .__text-subtitle {
                    @apply w-full;

                    .__text-content {
                        @apply flex flex-wrap font-montserrat text-left text-xs font-medium text-slate-200 md:font-semibold md:text-sm whitespace-break-spaces;
                    }
                }

                .__text-summary {
                    @apply font-roboto whitespace-normal text-xs tracking-tighter text-slate-400 sm:text-sm sm:tracking-tight line-clamp-2;
                }
            }

            .__genres {
                @apply w-auto flex-none h-8;

                .__genre {
                    @apply mr-1;
                }
            }

            .__festivals {
                @apply w-auto flex-none h-8;

                .__festival {
                    @apply text-xs font-medium mr-1 md:font-semibold tracking-tight;

                    a {
                        @apply block max-w-32 2xl:max-w-40 truncate;
                    }
                }
            }

            .__actions {
                @apply items-end space-x-2 flex;
            }

            .__custom-actions {
                @apply items-end space-x-2 flex;
            }
        }
    }

    .__aside {
        @apply flex flex-col items-center;

        .__aside-content {
            @apply flex-1 flex flex-col;
        }

        .__checkmark-container {
            @apply flex-1 flex flex-col justify-end sm:pt-4;

            .__checkmark {
                @apply flex items-center justify-center rounded-full shadow-md transition;
            }
        }
    }

    .__fav-artists {
        @apply flex flex-col items-center justify-center;

        .__count {
            @apply inline-flex h-4 min-w-[1rem] flex-none items-center justify-center rounded-full bg-bright-turquoise-500 px-1 text-xxs font-semibold text-cerulean-950;
        }

        .__text {
            @apply font-montserrat text-center text-[9px] sm:text-xxs font-semibold uppercase leading-none tracking-tighter text-bright-turquoise-600 block;
        }
    }

    &.tile--enabled {
        @apply hover:bg-slate-900;
    }

    &.tile--checked {
        .__checkmark {
            @apply bg-bright-turquoise-500 text-cerulean-950;
        }
    }

    &.tile--unchecked {
        .__checkmark {
            @apply bg-slate-900 text-slate-800;
        }
    }

    &:hover {
        .__checkmark {
            @apply bg-white;
        }
    }

    &.tile--artist {
        .__image-container {
            @apply mx-auto aspect-square rounded-full sm:h-40 sm:w-40 2xl:h-48 2xl:w-48;

            .__image-elem,
            .__image-placeholder {
                @apply rounded-full;
            }
        }
    }

    &.tile--style-box {
        @apply flex flex-col space-y-4 sm:space-y-8;

        &.tile--has-aside {
            .__aside {
                @apply items-start;

                .__aside-content {
                    .__fav-artists {
                        @apply space-y-0 flex-row space-x-1 2xl:space-x-0 2xl:flex-col 2xl:justify-center w-full 2xl:w-auto 2xl:space-y-1;

                        .__text {
                            @apply flex-1 md:flex-none md:text-center flex space-x-0.5 2xl:block 2xl:space-x-0;
                        }
                    }
                }
            }
        }
    }

    &.tile--style-row {
        @apply flex overflow-hidden space-x-2 sm:p-2 md:space-x-4 md:p-4 sm:space-y-0 sm:items-center;

        .__image-container {
            @apply flex h-auto w-20 flex-none flex-col justify-center md:h-24 md:w-24 3xl:w-28 3xl:h-28;

            .__image-elem {
                @apply h-20 md:h-24 3xl:h-28;
            }
        }

        .__content {
            @apply flex-1;

            .__left {
                @apply flex-col justify-center space-y-2;

                .__text-container {
                    @apply w-auto;

                    .__text-title {
                        @apply sm:text-xs md:text-sm lg:text-base;
                    }

                    .__text-subtitle {
                        .__text-content {
                            @apply sm:text-xs;
                        }
                    }
                }

                .__text-summary {
                    @apply sm:text-xs;
                }

                .__genres,
                .__festivals,
                .__actions,
                .__custom-actions {
                    @apply flex-none;
                }
            }
        }

        &.tile--has-aside {
            .__aside {
                .__aside-content {
                    .__fav-artists {
                        @apply space-y-1;
                    }
                }
            }
        }
    }

    &.tile--size-small {
        @apply sm:space-y-4;

        .__image-container {
            @apply relative aspect-square w-32 h-32 shadow-xl lg:h-24 lg:w-24 2xl:h-32 2xl:w-32;
        }

        .__content {
            @apply space-y-2;

            .__text-container {
                .__text-title {
                    @apply text-sm font-semibold;
                }
            }
        }

        &.tile--style-row {
            @apply sm:space-y-0 sm:px-2 sm:py-4;

            .__image-container {
                @apply w-12 h-12 flex-none md:w-16 md:h-16;

                .__image-elem,
                .__image-placeholder {
                    @apply h-12 w-12 md:h-16 md:w-16;
                }
            }
        }
    }

    &.tile--active {
        @apply bg-slate-800;
    }

    &.tile--disabled {
        @apply bg-slate-800;

        .__image-container,
        .__content,
        .__aside {
            @apply opacity-40 pointer-events-none;
        }
    }

    &.tile--height-full {
        @apply h-full;
    }

    &.tile--height-auto {
        @apply h-auto;
    }

    &.tile--has-aside {
        .__content {
            @apply space-y-2 md:space-y-4 2xl:space-y-0 flex-col 2xl:flex-row 2xl:space-x-4;
        }
    }

    &.tile--title-clamp-1 {
        .__content {
            .__text-container {
                .__text-title {
                    @apply line-clamp-1;
                }
            }
        }
    }

    &.tile--title-clamp-2 {
        .__content {
            .__text-container {
                .__text-title {
                    @apply line-clamp-2;
                }
            }
        }
    }

    &.tile--title-clamp-3 {
        .__content {
            .__text-container {
                .__text-title {
                    @apply line-clamp-3;
                }
            }
        }
    }
}
